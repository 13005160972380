import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

let urutan = 0

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      pilihBalai: null,
      pilihProject: null,
      pilihSatker: null,
      isActive: [],
      listBalai: [],
      listProject: [],
      listStatker: [],
      listDataPaket: [],
      listGiat:[],
      listRegister:[],
      listKategori:[],
      listKodeAkun:[
        {'text': '521211 - Belanja Bahan','value': 'c_1'},
        {'text': '521213 - Belanja Honor Output Kegiatan','value': 'c_1'},
        {'text': '521811 - Belanja Barang Persediaan Barang Konsumsi','value': 'c_1'},

      ],
      tbData: [],
      pgMin:0,
      pgMax:0,
      noUrut:1,
      columnTab:[
        {'text': 'Kode','value': 'c_1'},
        {'text': 'Kegiatan/Output/Paket/Sub Paket','value': 'c_2'},
        {'text': 'Target Volume','value': 'c_3'},
        {'text': 'Lokasi','value': 'c_4'},
        {'text': 'Jenis Paket','value': 'c_5'},
        {'text': 'Metode Pemilihan','value': 'c_6'},
        {'text': 'Kode Proyek','value': 'c_7'},
        {'text': 'Kode Register','value': 'c_8'},
        {'text': 'Kode KPPN','value': 'c_9'},
        {'text': 'Kode Beban','value': 'c_10'},
        {'text': 'Pagu (Rp Ribu)','value': 'c_11'},
        {'text': 'Realisasi (Rp Ribu)','value': 'c_12'},
        {'text': '% KEU','value': 'c_13'},
        {'text': '% FISIK','value': 'c_14'}

      ]
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.G_GetRef('balai').then(data => {
      this.listBalai = data;
      this.listBalai.unshift({
        'text': 'Semua Balai',
        'value': 'all'
      })
    });
    this.G_GetRef('proyek').then(data => {
      this.listProject = data;
      this.listProject.unshift({
        'text': 'Semua Proyek',
        'value': 'all'
      },
      {
        'text': 'Paket Terdapat Data Proyek',
        'value': 'hasProyek'
      })
    });
    this.G_GetRef('satker').then(data => {
      this.listStatker = data;
        this.listStatker.unshift({
        'text': 'Semua Satker',
        'value': 'all'
      })
    });
    this.G_GetRef('register').then(data => {
      this.listRegister = data;
        this.listRegister.unshift({
        'text': 'Semua Register',
        'value': 'all'
      })

    });
    this.G_GetRef('kategori').then(data => {
      this.listKategori = data;
        this.listKategori.unshift({
        'text': 'Semua Kategori',
        'value': 'all'
      })

    });
    this.G_GetRef('giat').then(data => {
      this.listGiat = data;
        // this.listGiat.unshift({
        //   'text': 'Semua Giat',
        //   'value': ''
        // })
    });

  },
  components: {
    myTable
  },
  methods: {
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];
      if (this.isActive[key]) {
        $('#sub_' + key).hide();
      } else {
        $('#sub_' + key).show();

      }
    },
    test(){
      console.log('wadaw');
    },
    cariData() {
      // this.$refs.giat.selectAll()
      urutan = 0
      let myForm = document.getElementById('formCari');
      let formData = new FormData(myForm);

      var object = {};
      formData.forEach((value, key) => object[key] = value);

      let filter = '';

      if (object.balai) {
        if (object.balai != 'all'){
          filter += '&kdbalai='+object.balai
        }
      }
      if (object.satker) {
        if (object.satker != 'all'){
          filter += '&kdsatker='+object.satker
        }
      }
      if (object.projek) {
        if (object.projek != 'all') {
          if (object.projek == 'hasProyek') {
            filter += '&hasProyekOnly=true'
            
          }else{
            filter += '&proyek='+object.projek
          }
        }
       
      }
      if (object.giat) {
        filter += '&kdgiat='+object.giat
      }
      if (object.beban) {
        filter += '&beban='+object.beban
      }
      if (this.pgMin > 0) {
        filter += '&pgMin='+this.pgMin*1000
      }
      if (this.pgMax > 0) {
        filter += '&pgMax='+this.pgMax*1000
      }
      if (object.kdkategori) {
        filter += '&kdkategori='+object.kdkategori
      }
      if (object.kdkppn) {
        filter += '&kdkppn='+object.kdkppn
      }

      if(object.colomTable){
        $('*[class^="c_"]').hide()

        console.log(object.colomTable);
        let cl = object.colomTable

        cl = cl.split(",");

        console.log(cl);
        cl.forEach(v => {
          $('.'+v).show();
        });
      }

      console.log(filter);

      filter = `&kdsatker=030156&hasProyekOnly=true`

      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true)
        this.G_GetAny('progres/per-paket/nested?tahun=2021'+filter).then(res => {

        // this.G_GetAny('rkakl/data?saktiVersionId=438de16c-7637-42cd-8a7f-b171b1f045cb').then(res => {
          console.log(res)
          this.listDataPaket = res.data.content
          $('#tableShow').fadeIn()
          this.$refs.btnAct.load(false)
          this.loadingTb = false

        })
      }

    },
    setNoUrut(idx){
     
      // urutan++ 
      // return urutan
    },
    setSatker(id){
      if (id != 'all' || id == null) {
        this.G_GetRef('satker','?balaiId='+id).then(data => {
          this.listStatker = data;
            this.listStatker.unshift({
            'text': 'Semua Satker',
            'value': 'all'
          })
        });
      }else{
        this.G_GetRef('satker').then(data => {
          this.listStatker = data;
            this.listStatker.unshift({
            'text': 'Semua Satker',
            'value': 'all'
          })
        });
      }
     

    },
    tableCSV(){
      var workbook = XLSX.utils.book_new();
        
      
        var worksheet_data  = document.getElementById("tableShow");
        var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
        
        workbook.SheetNames.push("Test");
        workbook.Sheets["Test"] = worksheet;
      
        //  exportExcelFile(workbook);

         return XLSX.writeFile(workbook, 'Progres Per Paket '+this.G_today+'.xlsx');
        var data = ['waw'];
        var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        var objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
    },
    tableExcel(){

    var a = document.createElement('a');
    //getting data from our div that contains the HTML table
    var data_type = 'data:application/vnd.ms-excel';
    var table_div = document.getElementById('tableShow');
    var table_html = table_div.outerHTML.replace(/ /g, '%20');
    a.href = data_type + ', ' + table_html;
    //setting the file name
    a.download = 'Progres Per Paket '+this.G_today+'.xls';
    //triggering the function
    a.click();
    //just in case, prevent default behaviour
    e.preventDefault();

    // window.open('data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent( $('div[id$=tableShow]').html()));
    // e.preventDefault();
    },
    selectAll(type){
      if(type == 'kolom'){
        this.$refs.colomTable.selectAll()
      }
      else if(type == 'giat'){
        this.$refs.giat.selectAll()

      }
    },
    removeAll(type){
      if(type == 'kolom'){
        this.$refs.colomTable.removeAll()
      }
      else if(type == 'giat'){
        this.$refs.giat.removeAll()

      }
    },
    persetujuan(id){
        this.$refs.modalPersetujuan.open("Persetujuan Perubahan", 600)
    
    },
    komentarList(id){
      this.$refs.modalKomentar.open("Komentar", 800)

    },
   
    storeSubKomponen(){

    },
    storeSubOutput(){

    }
  }
}